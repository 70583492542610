




























































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { LandlordRegistration } from "@/model/LandlordRegistration";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton 
    }
})
export default class LandlordRegister extends Vue {

    //
    // -- properties
    //

    @Ref("landlordRegisterForm") private readonly landlordRegisterForm!: VForm;  

    private registration: LandlordRegistration = new LandlordRegistration();
    completed: boolean = false;
    captchaShouldValidateOnChange = false;
    captchaErrorMessages: Array<string> = [];
    submitButtonDisabled: boolean = false;

    captcha = {
        captchaID: "",
        captchaText: ""
    }

    private emailErrorMessages: Array<string> = [];

    @Watch("registration.emailAddress")
    private async validateEmail() {
        this.emailErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.registration.emailAddress)) {
            this.submitButtonDisabled = false;
            return;
        }

        const response: { isOkay: boolean; message: string } = await apiClient.get(`/api/email/emailFormat?email=${this.registration.emailAddress}`);
        if (response.isOkay) {
            this.emailErrorMessages = [];
        }
        else if (!response.isOkay && this.emailErrorMessages.indexOf(response.message) == -1) {
            this.emailErrorMessages = [];
            this.emailErrorMessages.push(response.message);
            this.submitButtonDisabled = false;
            return;
        }
    }

    private phoneErrorMessages: Array<string> = [];

    @Watch("registration.phoneNumber")
    private async validatePhone() {
        this.phoneErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.registration.phoneNumber)) {
            this.submitButtonDisabled = false;
            return;
        } 

        const response: { isOkay: boolean; message: string } = await apiClient.get(`/api/email/phoneFormat?phone=${this.registration.phoneNumber}`);
        if (response.isOkay) {
            this.phoneErrorMessages = [];
        }
        else if (!response.isOkay && this.phoneErrorMessages.indexOf(response.message) == -1) {
            this.phoneErrorMessages = [];
            this.phoneErrorMessages.push(response.message);
            this.submitButtonDisabled = false;
            return;
        }
    }

    @Watch("captchaText")
    onCaptchaChange() {
        if(this.captchaShouldValidateOnChange) { 
            this.validateCaptcha()
        }
    }

    //
    // -- methods
    //

    async mounted(): Promise<void> {
        await this.refreshCaptcha();
    }

    get captchaSrc() { 
        // default image if error?
        return apiClient.resolveUrl(`api/captcha/image?id=${this.captcha.captchaID}`);
    }

    async refreshCaptcha(): Promise<void> {
        const resp: { id: string } = await apiClient.get("/api/captcha/create");
        this.captcha.captchaID = resp.id ?? "";
        this.captchaErrorMessages.length = 0;
    }

    onCaptchaBlur() {
        // start validating after field has been 'visited'
        this.captchaShouldValidateOnChange = true;
        this.validateCaptcha();
    }

    async validateCaptcha(): Promise<void> {
        this.captchaErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.captcha.captchaText)) { 
            this.captchaErrorMessages.length = 0;
            this.captchaErrorMessages.push("Please enter the text");
            this.submitButtonDisabled = false;
            return; 
        }
        const response: { message: string } = await apiClient.get(`/api/captcha/verify?id=${this.captcha.captchaID}&text=${encodeURIComponent(this.captcha.captchaText)}`);
        if (response.message !== "ok") {
            this.captchaErrorMessages.push("The text is incorrect");
        }
    }

    private async save() {
        this.submitButtonDisabled = true;

        await this.validateEmail();
        await this.validatePhone();
        await this.validateCaptcha();
        const isValid = this.landlordRegisterForm.validate() && this.emailErrorMessages.length === 0 && this.phoneErrorMessages.length === 0 && this.captchaErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            this.submitButtonDisabled = false;
            return;
        }

        const response: ISaveResponse = await apiClient.post("/api/landlord/saveLandlordRegistration", this.registration);

        if (response.isSuccess) {
            toastr.success("Registration submitted successfully, please await email confirmation.");
            this.completed = true;
            this.refreshCaptcha();
            this.submitButtonDisabled = false;
        } else {
            toastr.error("The registration has not submitted successfully. If this error persists please contact us directly.");
            this.submitButtonDisabled = false;
        }

        this.submitButtonDisabled = false;
        
    }
}
